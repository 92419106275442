import react, { useEffect } from 'react'

const FlowCodePrivacyPage = () => {
  useEffect(() => {
    window.location = 'https://www.flowcode.com/privacy-policy'
  }, [])
  return null
}

export default FlowCodePrivacyPage
